export const apiCallScheduler = {
    timingFunction : null,

    setFn(fn) {
        this.timingFunction = fn;
    },

    clearFn() {
        clearTimeout(this.timingFunction)
    }
}


export const apiCallTimer = 60000;
